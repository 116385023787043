<template>
  <div>

    <HeaderMain />

    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form w-container">
        <div class="from-login w-form">
          <form id="wf-form-Login" name="wf-form-Login" data-name="Login" method="get" class="form-input-login" data-wf-page-id="66628b11ce9ac8f5a081c327" data-wf-element-id="80cb5f26-991f-0842-cdc6-d86456db53e0">
            <v-form ref="form_reg1" v-model="valid_log" lazy-validation>
              <h1 class="heading-form">Вход в личный кабинет</h1>
              <div class="input-box" style="margin-top: 20px;">
                <label class="input-label">Телефон</label>
                <v-text-field
                    outlined
                    inputmode="numeric"
                    :rules="phoneRules"
                    v-mask="'+7##########'"
                    v-model="phone"
                ></v-text-field>
              </div>
              <input
                  style="width: 100%;"
                  type="submit"
                  @click="sendPhone"
                  class="button-bust w-button"
                  value="Войти">
            </v-form>
          </form>
          <div class="text-classic">Нет акаунта? <a @click="goToReg" class="link-form-login">Зарегистрироваться</a>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import loadjs from 'loadjs';
import { Footer, HeaderMain } from '/src/app/shared/components';

export default {
  name: 'Login',
  data() {
    return {
      phoneRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^(8\d{10}|\+7\d{10})$/.test(v) || 'Телефон должен состоять из 11 цифр и начинаться с +7'
      ],
      valid_log: false,
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  created() {
  },
  computed: {
    phone: {
      get() {
        return this.$store.getters.PHONE;
      },
      set(value) {
        this.$store.commit('SET_PHONE', value);
      }
    }
  },
  methods: {
    goToUser() {
      this.$router.push('/main');
    },
    goToHome() {
      this.$router.push('/');
    },
    goToReg() {
      this.$router.push('/registration-step1');
    },
    sendPhone(event) {
      event.preventDefault();
      if (this.$refs.form_reg1.validate()) {
        let payload = {
          phone: this.phone,
          type: 'authorization'
        }
        this.$store.dispatch('NEW_CODE_AUTH', payload).then(() => {
          this.$router.push('/login-check-phone');
        }).catch((message) => {
          console.error('Ошибка отправки кода: ', message);

          this.$buefy.toast.open({
            message: message,
            type: 'is-danger'
          });
        });
      } else {
        this.$buefy.toast.open({
          message: "Введите корректный телефон!",
          type: 'is-danger'
        });
      }
    },
  }

};
</script>

<style>
</style>
